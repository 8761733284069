import * as React from "react";
import styled from "@emotion/styled/macro";
import vars from "src/design-system/variables";

const Dropdown = ({
  dataTestId,
  className,
  props,
  onChange,
  options,
  selected,
  isDisabled,
}) => {
  return (
    <select
      data-testid={dataTestId}
      className={className}
      onBlur={onChange}
      onChange={onChange}
      value={selected}
      disabled={isDisabled}
      {...props}
    >
      {options ? (
        options.map((option) => {
          return (
            <option key={option.id} value={option.id}>
              {option.display}
            </option>
          );
        })
      ) : (
        <option value="invalid">No options passed</option>
      )}
    </select>
  );
};

const StyledDropdown = styled(Dropdown)`
  height: 30px;
  border: 1px solid ${vars.gandalfGrey};
  font-size: 16px;
  &[disabled] {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: "";
  }
`;

export default StyledDropdown;
