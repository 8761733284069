import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import vars from "src/design-system/variables";
import ReviewSubunitHeader from "src/design-system/components/review/review-subunit-header/review-subunit-header";
import ReviewSubunitRow from "src/design-system/components/review/review-subunit-row/review-subunit-row";

const ReviewSurveyList = ({
  className,
  items,
  responseId,
  updateSubunitsData,
}) => {
  const [subunits, setSubunits] = useState(items);

  const updateDataHandle = (updatedRow, rowIndex) => {
    console.log("Updating subunit list");
    setSubunits([
      ...subunits.slice(0, rowIndex),
      updatedRow,
      ...subunits.slice(rowIndex + 1),
    ]);
    updateSubunitsData(updatedRow, rowIndex);
  };
  return (
    <div className={className}>
      <ul className="subunits">
        <li>
          <ReviewSubunitHeader />
        </li>
        {subunits.map((subunit, rowIndex) => {
          return (
            <li key={subunit.businessEntityId} className="subunit">
              <ReviewSubunitRow
                data={subunit}
                responseId={responseId}
                updateSubunitRow={(updatedRow) =>
                  updateDataHandle(updatedRow, rowIndex)
                }
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const StyledReviewSurveyList = styled(ReviewSurveyList)`
  .subunits {
    list-style-type: none;
    margin-top: 0.5rem;
    margin-left: 1rem;
    border-left: 1px solid ${vars.grimboldGrey};
    padding-left: 1rem;
    .header {
      background-color: ${vars.grimboldGrey};
      margin-bottom: 0.5rem;
    }
  }
  .subunit {
    margin-bottom: 0.25rem;
  }
  .subunit::before {
    content: "";
    display: block;
    width: 1rem;
    height: 2px;
    background-color: ${vars.grimboldGrey};
    position: relative;
    left: -1rem;
    top: 2.3rem;
  }
`;

export default StyledReviewSurveyList;
