import { useCallback, useState } from "react";
import { getPreSignedUrl } from "src/utils/signedAwsUrl";

function useS3Download(s3Url) {
  const [hasS3Url] = useState(!!s3Url);
  const download = useCallback(async () => {
    if (!hasS3Url) {
      return;
    }

    const url = await getPreSignedUrl(s3Url);
    window.open(url, "_blank", "noreferrer");
  }, [hasS3Url, s3Url]);
  return {
    hasS3Url,
    download,
  };
}

export default useS3Download;
