import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled/macro";
import vars from "src/design-system/variables";

const PageNav = ({ className }) => {
  return (
    <nav className={className}>
      <ul className="nav-items">
        <li>
          <div className="nav-item">
            <Link to="/circulytics/review">Review</Link>
          </div>
        </li>
        <li>
          <div className="nav-item">
            <Link to="/circulytics/benchmarks">Benchmarks</Link>
          </div>
        </li>
        <li>
          <div className="nav-item">
            <Link to="/circulytics/scorecards">Scorecards</Link>
          </div>
        </li>
      </ul>
    </nav>
  );
};

const StyledPageNav = styled(PageNav)`
  margin-bottom: 1rem;
  .nav-items {
    list-style-type: none;
    display: flex;
  }
  .nav-item {
    background-color: ${vars.gandalfGrey};
    margin-right: 1rem;
    padding: 0.25rem 0.5rem;
    a {
      color: ${vars.coreDarkGrey};
    }
    &:hover {
      background-color: ${vars.coreDarkGrey};
      a {
        color: white;
      }
    }
  }
`;

export default StyledPageNav;
