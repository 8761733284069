import React from "react";
import styled from "@emotion/styled/macro";
import vars from "src/design-system/variables";
import DataRow from "src/design-system/components/data-row/data-row";
import Textarea from "src/design-system/atoms/textarea";
import ReviewDropdown from "src/design-system/components/review/fields/review-dropdown/review-dropdown";
import Button from "src/design-system/atoms/button";
import { LinkButton } from "src/design-system/atoms/button";
import TooltipText from "src/design-system/atoms/tooltipText";
import { formatDate } from "src/utils/dateHelpers";
import formatRevenue from "src/utils/formatRevenue";
import { circulyticsReviewOptions } from "src/design-system/components/review/fields/review-dropdown/dropdown-options.data";
import useScorecardDownload from "src/design-system/atoms/useScorecardDownload";
import useS3Download from "src/design-system/atoms/useS3Download";

const MAX_LEN_FOR_COMMENTARY = 2500;
const MAX_LEN_FOR_COMMENTARY_BULLETS = 100;

const ReviewWholeCompanyDataRow = ({
  className,
  data,
  updateState,
  showSubunits,
  setSubunitsVisibility,
}) => {
  const scorecards = data["whole-company"].scorecardUrls;
  const { hasScorecards, downloadScorecard, latestScorecard } =
    useScorecardDownload(scorecards);
  const sdg_url = data["whole-company"].sdgUrl;
  const { hasS3Url: hasSdg, download: downloadSdg } = useS3Download(sdg_url);
  const detailed_results_url = data["whole-company"].detailedResultsUrl;
  const { hasS3Url: hasDetailedResults, download: downloadDetailedResults } =
    useS3Download(detailed_results_url);

  return (
    <DataRow
      className={className}
      columnWidths={vars.reviewWholeCompanyColumnWidths}
    >
      <div className="survey-info">
        <h3 className="company-name label">{data.companyName}</h3>
        <div />
        <div className="survey-info-items">
          <div className="label">Circulytics ID</div>
          <div className="value">{data.circulyticsId}</div>
          <div className="label">Response ID</div>
          <div className="value">{data.responseId}</div>
          <div className="label">Submission Date</div>
          <div className="value">{formatDate(data.recordedDate)}</div>
          <div className="label">FY / Version</div>
          <div className="value">
            {data.financialYear} / v
            {data.circulyticsJsonSchemaVersion.slice(0, 3)}
          </div>
          <div className="label">Submission Type</div>
          <div className="value">{data["whole-company"].submission_type}</div>
          <div className="label">Industry class.</div>
          <div className="value">
            {data["whole-company"].industryClassification}
          </div>
          <div className="label">Annual Revenue</div>
          <div className="value">
            {formatRevenue(data["whole-company"].totalAnnualRevenue)}
          </div>
          <div className="label">Network Member</div>
          <div className="value">{data.networkMember}</div>
          <div className="label">Scope</div>
          <div className="value">{data["whole-company"].scope}</div>
          <h4 title="These are internal only notes and will not be shared with the organisation">
            Analyst notes
          </h4>
          <Textarea
            onChange={(e) => updateState(e, "analystNotes")}
            height={75}
            text={data["whole-company"].analystNotes}
          />
        </div>
      </div>
      <div className="survey-review">
        <div className="label">Final Data</div>
        <div>
          <a
            href={data.serviceOutputData.final_data}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>
        </div>
        <div className="label">Scorecard</div>
        <div>
          <LinkButton
            isDisabled={!hasScorecards}
            onClick={downloadScorecard}
            href={latestScorecard}
          >
            Download
          </LinkButton>
        </div>
        <div className="label">Detailed results</div>
        <div>
          <LinkButton
            isDisabled={!hasDetailedResults}
            onClick={downloadDetailedResults}
            href={detailed_results_url}
          >
            Download
          </LinkButton>
        </div>
        <div className="label">SDG</div>
        <div>
          <LinkButton isDisabled={!hasSdg} onClick={downloadSdg} href={sdg_url}>
            Download
          </LinkButton>
        </div>
        <div className="label">Overall Score</div>
        <div>
          <strong>{data["whole-company"].overall_score}</strong>
        </div>
        <TooltipText
          labelText="Data valid"
          tooltipText="Check whether the data submitted is of good enough quality. If validation errors/warnings are displayed in the draft scorecard rawData tab, or if there are otherwise doubts about the company's interpretation of indicators or reliability of data provided, select 'Unapproved' and contact them via email."
        />
        <ReviewDropdown
          options={circulyticsReviewOptions}
          onChange={(e) => updateState(e, "isDataValid")}
          selected={data["whole-company"].isDataValid}
        />
        <TooltipText
          labelText="Benchmark"
          tooltipText="1. Check whether the company has a custom scope. If the submission does not cover the entire company, select 'Unapproved'; 2. Check the Data valid flag above. If emails confirm lack of data validity, select 'Unapproved'  for Benchmarks."
        />
        <ReviewDropdown
          options={circulyticsReviewOptions}
          onChange={(e) => updateState(e, "approvedForBenchmarking")}
          selected={data.approvedForBenchmarking}
        />
        <TooltipText
          labelText="Historical data"
          tooltipText="Check whether the company has a custom scope / the data is valid. Automated inclusion of historical data from is not yet implemented, so please manually add this company's previous submission data to the final scorecard Score History Chart if required."
        />
        <ReviewDropdown
          options={circulyticsReviewOptions}
          onChange={(e) => updateState(e, "approvedForHistoricalData")}
          selected={data.approvedForHistoricalData}
        />
        <TooltipText
          labelText="Disclosure"
          tooltipText="1. Check 'Scope' to determine whether the response encompasses the whole company. If company selected 'Customised Scope', set flag to 'Unapproved'; 2. Check the draft scorecard rawData tab to check whether the company has provided supporting evidence to each displayed (D) scored indicator (Themes 1-11); 3. Check the Data valid flag above. If emails confirm lack of data validity, allow select 'Unapproved' for Disclosure."
        />
        <ReviewDropdown
          options={circulyticsReviewOptions}
          onChange={(e) => updateState(e, "approvedForDisclosure")}
          selected={data.approvedForDisclosure}
        />
      </div>
      <div className="survey-commentary">
        <h4>Commentary</h4>
        <Textarea
          onChange={(e) => updateState(e, "commentary")}
          height={100}
          text={data["whole-company"].commentary}
          maxlength={MAX_LEN_FOR_COMMENTARY}
        />
        <h4>Additional points</h4>
        <Textarea
          onChange={(e) => updateState(e, "commentaryBullet1")}
          text={data["whole-company"].commentaryBullet1}
          maxlength={MAX_LEN_FOR_COMMENTARY_BULLETS}
        />
        <Textarea
          onChange={(e) => updateState(e, "commentaryBullet2")}
          text={data["whole-company"].commentaryBullet2}
          maxlength={MAX_LEN_FOR_COMMENTARY_BULLETS}
        />
        <Textarea
          onChange={(e) => updateState(e, "commentaryBullet3")}
          text={data["whole-company"].commentaryBullet3}
          maxlength={MAX_LEN_FOR_COMMENTARY_BULLETS}
        />
      </div>
      {data["sub-units"] && data["sub-units"].length > 0 ? (
        <Button
          className="expander"
          data-testid="subunits-toggle"
          onClick={() => setSubunitsVisibility(!showSubunits)}
        >
          {showSubunits ? "Hide" : "Show"} Subunits ({data["sub-units"].length})
        </Button>
      ) : (
        <div></div>
      )}
    </DataRow>
  );
};

const StyledReviewWholeCompanyDataRow = styled(ReviewWholeCompanyDataRow)`
  .company-name {
    margin-bottom: 1.5rem;
  }
  .survey-info,
  .survey-review,
  .survey-commentary {
    background-color: white;
    padding: 1rem;
    border: 1px solid ${vars.gandalfGrey};
  }
  .survey-info-items,
  .survey-review {
    display: grid;
    grid-template-columns: 33% 67%;
    grid-row-gap: 0.75rem;
    h3 {
      font-weight: 900;
    }
    .label {
      border-bottom: 1px solid ${vars.gandalfGrey};
      padding: 0;
    }
    .value {
      background-color: ${vars.gandalfGrey};
      padding: 0.1rem 0.3rem;
    }
  }
  .survey-review {
    grid-template-columns: 50% 50%;
  }
  .survey-commentary {
    h4 {
      margin: 0.5rem 0;
    }
  }
`;

export default StyledReviewWholeCompanyDataRow;
