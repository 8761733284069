function numberWithCommas(revenue) {
  if (revenue === null || typeof revenue === "undefined" || revenue === "") {
    return "0";
  }
  return revenue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function formatRevenue(revenue) {
  return "$" + numberWithCommas(revenue);
}
export default formatRevenue;
