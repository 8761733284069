import React from "react";
import styled from "@emotion/styled/macro";
import vars from "src/design-system/variables";
import DataRow from "src/design-system/components/data-row/data-row";
import ReviewDropdown from "src/design-system/components/review/fields/review-dropdown/review-dropdown";
import TooltipText from "src/design-system/atoms/tooltipText";
import { LinkButton } from "src/design-system/atoms/button";
import formatRevenue from "src/utils/formatRevenue";
import { circulyticsReviewOptions } from "src/design-system/components/review/fields/review-dropdown/dropdown-options.data";
import useScorecardDownload from "src/design-system/atoms/useScorecardDownload";

const ReviewSubunitDataRow = ({ className, data, updateState }) => {
  const scorecards = data.scorecardUrls;
  const { hasScorecards, downloadScorecard, latestScorecard } =
    useScorecardDownload(scorecards);

  return (
    <DataRow
      className={className}
      columnWidths={vars.reviewSubunitColumnWidths}
    >
      <div className="survey-info">
        <div className="label">Subunit Name</div>
        <div className="value">{data.subUnitName}</div>
        <div className="label">Annual Revenue</div>
        <div className="value">{formatRevenue(data.totalAnnualRevenue)}</div>
      </div>
      <div className="survey-review">
        <div className="label">Scorecard</div>
        <div>
          {hasScorecards ? (
            <LinkButton
              dataTestId="download-scorecard"
              onClick={downloadScorecard}
              href={latestScorecard}
            >
              Download
            </LinkButton>
          ) : (
            <span>No scorecard found</span>
          )}
        </div>
        <TooltipText
          labelText="Data valid"
          tooltipText="Check whether the data submitted is of good enough quality. If validation errors/warnings are displayed in the draft scorecard rawData tab, or if there are otherwise doubts about the company's interpretation of indicators or reliability of data provided, select 'Unapproved' and contact them via email."
        />
        <ReviewDropdown
          dataTestId={"review-dropdown-isDataValid"}
          options={circulyticsReviewOptions}
          onChange={(e) => updateState(e, "isDataValid")}
          selected={data.isDataValid}
        ></ReviewDropdown>
      </div>
    </DataRow>
  );
};
const StyledReviewSubunitDataRow = styled(ReviewSubunitDataRow)`
  .survey-info,
  .survey-review {
    display: grid;
    grid-template-columns: 45% 55%;
    grid-row-gap: 0.4rem;
    height: 4rem;

    .label {
      font-weight: 700;
      border-bottom: 1px solid ${vars.gandalfGrey};
      padding: 0.1rem 0;
    }
    .value {
      background-color: ${vars.gandalfGrey};
      padding: 0.1rem 0.3rem;
    }
  }
`;
export default StyledReviewSubunitDataRow;
