import React, { useState } from "react";
import { API } from "aws-amplify";
import styled from "@emotion/styled/macro";
import LoadingRow from "src/design-system/components/loading-row/loading-row";
import ReviewSubunitDataRow from "src/design-system/components/review/review-subunit-data-row/review-subunit-data-row";
import { StyledReviewControl as ReviewControl } from "src/design-system/components/review/review-control/review-control";
import vars from "src/design-system/variables";

const ReviewSubunitRow = ({
  className,
  data,
  updateSubunitRow,
  responseId,
}) => {
  const flagsToStrings = (row) => {
    row.isDataValid = String(row.isDataValid);
    return row;
  };
  const transformRow = (row) => {
    row = flagsToStrings(row);
    return row;
  };
  const [rowData, setRowData] = useState(transformRow(data));
  const [status, setStatus] = useState({ type: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);

  const saveToAPI = async () => {
    setIsLoading(true);
    const payload = {
      responseId: responseId,
      businessEntityId: rowData.businessEntityId,
      commentary: rowData.commentary,
      commentaryBullet1: rowData.commentaryBullet1,
      commentaryBullet2: rowData.commentaryBullet2,
      commentaryBullet3: rowData.commentaryBullet3,
    };
    if (rowData.isDataValid !== "none") {
      payload.isDataValid = Boolean(rowData.isDataValid);
    }
    await API.post("circulytics-surveys", "/", { body: payload })
      .then((res) => {
        setStatus({ type: "success", message: "Saved subunit" });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.response);
          setStatus({ type: "error", message: err.response.data.response });
        } else {
          console.log(err);
          setStatus({ type: "error", message: err });
        }
      });
    setIsLoading(false);
  };

  const updateStateHandler = (e, field) => {
    const value = e.target.value;
    setRowData({ ...rowData, [field]: value });
  };

  const cancelHandler = () => {
    setRowData(data);
    setStatus({ type: "success", message: "Cancelled changes" });
  };
  const saveHandler = () => {
    return saveToAPI().then(() => {
      updateSubunitRow(rowData);
    });
  };

  return (
    <div className={className}>
      <div className="control-wrap">
        <LoadingRow isLoading={isLoading}>
          <ReviewSubunitDataRow
            data={rowData}
            updateState={updateStateHandler}
          />
          <ReviewControl
            onCancel={cancelHandler}
            onSave={saveHandler}
            status={status}
          />
        </LoadingRow>
      </div>
    </div>
  );
};

const StyledReviewSubunitRow = styled(ReviewSubunitRow)`
  border-bottom: 1px solid ${vars.grimboldGrey};
  padding: 0.5rem;
`;

export default StyledReviewSubunitRow;
