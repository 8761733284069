import React from "react";
import styled from "@emotion/styled";
import vars from "src/design-system/variables";
import Button from "src/design-system/atoms/button";
import Status from "src/design-system/atoms/status";

const ReviewControl = ({
  className,
  onCancel,
  onSave,
  onSaveComplete,
  status,
  disableSaveComplete,
}) => {
  return (
    <div className={className}>
      <div className="cancel-buttons">
        <div className="cancel-button">
          <Button
              dataTestId="review-control-cancel"
              onClick={onCancel}
              color={vars.circulyticsRed}
          >
            Cancel changes
          </Button>
        </div>
      </div>
      <Status status={status} />
      <div className="save-buttons">
        {onSave ? (
          <div className="save-button">
            <Button
                dataTestId="review-control-save"
                onClick={onSave}
                color={vars.learningGreen}
            >
              Save changes
            </Button>
          </div>
        ) : (
          ""
        )}
        {onSaveComplete ? (
          <div className="save-complete-button complete">
            <Button
              dataTestId="review-control-save-complete"
              isDisabled={disableSaveComplete}
              onClick={onSaveComplete}
              color={vars.barrowWightGrey}
              backgroundColor={vars.learningGreen}
            >
              Save & Complete
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const StyledReviewControl = styled(ReviewControl)`
  display: flex;
  justify-content: space-between;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  padding: 1rem 0;
  .save-buttons {
    display: flex;
    justify-content: space-between;
    .complete {
      margin-left: 1rem;
    }
  }
  button {
    height: 3rem;
  }
  button:disabled {
    background-color: grey;
    &:hover {
      cursor: not-allowed;
    }
  }
`;

export { ReviewControl, StyledReviewControl };
