import React, { useState } from "react";
import { API } from "aws-amplify";
import styled from "@emotion/styled/macro";
import vars from "src/design-system/variables";
import LoadingRow from "src/design-system/components/loading-row/loading-row";
import ReviewWholeCompanyDataRow from "src/design-system/components/review/review-wholecompany-data-row/review-wholecompany-data-row";
import { StyledReviewControl as ReviewControl } from "src/design-system/components/review/review-control/review-control";
import ReviewSubunitList from "src/design-system/components/review/review-subunit-list/review-subunit-list";
import Button from "src/design-system/atoms/button";

const ReviewSurveyRow = ({ className, data, updateSurveyRow }) => {
  const flagsToStrings = (row) => {
    row.approvedForBenchmarking =
      row.approvedForBenchmarking !== "null"
        ? String(row.approvedForBenchmarking)
        : "null";
    row.approvedForHistoricalData =
      row.approvedForBenchmarking !== "null"
        ? String(row.approvedForHistoricalData)
        : "null";
    row.approvedForDisclosure =
      row.approvedForDisclosure !== "null"
        ? String(row.approvedForDisclosure)
        : "null";

    row["whole-company"].isDataValid =
      row["whole-company"].isDataValid !== "null"
        ? String(row["whole-company"].isDataValid)
        : "null";
    return row;
  };
  const transformRow = (row) => {
    row = flagsToStrings(row);
    return row;
  };

  const [rowData, setRowData] = useState(transformRow(data));
  const [showSubunits, setSubunitsVisibility] = useState(false);
  const [status, setStatus] = useState({ type: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);

  const saveToAPI = async (isReviewed) => {
    setIsLoading(true);
    // Commits changes to the database
    const payload = {
      responseId: rowData.responseId,
      businessEntityId: rowData["whole-company"].businessEntityId,
      commentary: rowData["whole-company"].commentary,
      commentaryBullet1: rowData["whole-company"].commentaryBullet1,
      commentaryBullet2: rowData["whole-company"].commentaryBullet2,
      commentaryBullet3: rowData["whole-company"].commentaryBullet3,
      analystNotes: rowData["whole-company"].analystNotes,
      isReviewed,
    };
    if (rowData["whole-company"].isDataValid !== "null") {
      payload.isDataValid = rowData["whole-company"].isDataValid === "true";
    }
    if (rowData.approvedForBenchmarking !== "null") {
      payload.approvedForBenchmarking =
        rowData.approvedForBenchmarking === "true";
    }
    if (rowData.approvedForHistoricalData !== "null") {
      payload.approvedForHistoricalData =
        rowData.approvedForHistoricalData === "true";
    }
    if (rowData.approvedForDisclosure !== "null") {
      payload.approvedForDisclosure = rowData.approvedForDisclosure === "true";
    }
    await API.post("circulytics-surveys", "/", { body: payload });
  };
  const saveHandler = () => {
    // Calls API & updates local & parent state
    saveToAPI(false)
      .then(() => {
        updateSurveyRow(rowData);
        setStatus({ type: "success", message: "Saved progress" });
        setIsLoading(false);
      })
      .catch((err) => {
        const errMessage =
          err.response.data.response ?? err.response.data.error;
        console.log(errMessage);
        setStatus({ type: "error", message: errMessage });
        setIsLoading(false);
      });
  };
  const validateSurvey = () => {
    // Checks if WC & SUs are valid
    const validDataWC =
      rowData["whole-company"].isDataValid === "true" ||
      rowData["whole-company"].isDataValid === true;
    const validBenchmarkingWC = !(
      rowData.approvedForBenchmarking === "null" ||
      rowData.approvedForBenchmarking === "none"
    );
    const validHistoricalWC = !(
      rowData.approvedForHistoricalData === "null" ||
      rowData.approvedForHistoricalData === "none"
    );
    const validDisclosureWC = !(
      rowData.approvedForDisclosure === "null" ||
      rowData.approvedForDisclosure === "none"
    );
    const validBenchmarkingDisclosure = !(
      rowData.approvedForDisclosure === "false" &&
      rowData.approvedForBenchmarking === "true"
    );

    let validSUs = true;
    if (rowData["sub-units"]) {
      for (let i = 0; i < rowData["sub-units"].length; i++) {
        if (
          !(
            rowData["sub-units"][i].isDataValid === "true" ||
            rowData["sub-units"][i].isDataValid === true
          )
        ) {
          validSUs = false;
          break;
        }
      }
    }
    const isValid =
      validDataWC &&
      validBenchmarkingWC &&
      validHistoricalWC &&
      validDisclosureWC &&
      validBenchmarkingDisclosure &&
      validSUs;
    if (!validDataWC) {
      setStatus({
        type: "error",
        message:
          "You cannot complete this survey as the whole company does not have valid data",
      });
    } else if (!validBenchmarkingWC) {
      setStatus({
        type: "error",
        message:
          "Please review whether the survey should be included in benchmarking",
      });
    } else if (!validHistoricalWC) {
      setStatus({
        type: "error",
        message:
          "Please review whether the survey should display historical data",
      });
    } else if (!validDisclosureWC) {
      setStatus({
        type: "error",
        message: "Please review whether the survey is valid for disclosure",
      });
    } else if (!validBenchmarkingDisclosure) {
      setStatus({
        type: "error",
        message:
          "You cannot include this survey in the benchmarks as disclosure is set to unapproved",
      });
    } else if (!validSUs) {
      setStatus({
        type: "error",
        message:
          "You cannot complete this survey as one or more Sub unit does not have valid data",
      });
    }
    return isValid;
  };
  const saveCompleteHandler = () => {
    const isValid = validateSurvey();
    if (isValid) {
      return saveToAPI(true)
        .then((res) => {
          setRowData({ ...rowData, isReviewed: true });
          setStatus({
            type: "success",
            message: "Saved and completed the review",
          });
          setIsLoading(false);
        })
        .catch((err) => {
          const errMessage =
            err.response.data.response ?? err.response.data.error;
          console.log(errMessage);
          setStatus({ type: "error", message: errMessage });
          setIsLoading(false);
        });
    }
  };
  const undoHandler = () =>
    saveToAPI(false).then(() => {
      setRowData({ ...rowData, isReviewed: false });
      setStatus({ type: "success", message: "Restored survey" });
      setIsLoading(false);
    });
  const cancelHandler = () => {
    setRowData(data);
    setStatus({ type: "success", message: "Cancelled changes" });
  };
  const updateWholecompanyStateHandler = (e, field) => {
    // Triggered any time any field changes in the WC
    const { value } = e.target;
    if (
      field === "approvedForBenchmarking" ||
      field === "approvedForHistoricalData" ||
      field === "approvedForDisclosure"
    ) {
      // Update top level
      setRowData({ ...rowData, [field]: value });
    } else {
      // Update WC level
      setRowData({
        ...rowData,
        "whole-company": { ...rowData["whole-company"], [field]: value },
      });
    }
  };
  const updateSubunitsDataHandler = (updatedSubunit, subunitIndex) => {
    // Triggered when a SU is saved to update the WC's knowledge of the updated SU
    const tmpSubunits = [...rowData["sub-units"]];
    tmpSubunits[subunitIndex] = updatedSubunit;
    setRowData({ ...rowData, "sub-units": tmpSubunits });
  };
  return (
    <>
      {rowData.isReviewed === true ? (
        <div>
          {rowData.companyName} ({rowData.responseId}) Completed!{" "}
          <Button
            onClick={undoHandler}
            color="white"
            backgroundColor={vars.circulyticsRed}
          >
            Unreview
          </Button>
        </div>
      ) : (
        <div className={className}>
          <div className="control-wrap">
            <LoadingRow isLoading={isLoading}>
              <ReviewWholeCompanyDataRow
                data={rowData}
                updateState={updateWholecompanyStateHandler}
                showSubunits={showSubunits}
                setSubunitsVisibility={setSubunitsVisibility}
              />
              <ReviewControl
                onCancel={cancelHandler}
                onSave={saveHandler}
                onSaveComplete={saveCompleteHandler}
                status={status}
              />
            </LoadingRow>
          </div>
          {showSubunits ? (
            <ReviewSubunitList
              items={rowData["sub-units"]}
              responseId={rowData.responseId}
              updateSubunitsData={updateSubunitsDataHandler}
            />
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

const StyledReviewSurveyRow = styled(ReviewSurveyRow)`
  border-bottom: 1px solid ${vars.grimboldGrey};
  padding: 0;
  margin: 0.5rem 0;
  .data-row-wrap {
    text-align: left;
    background-color: ${vars.barrowWightGrey};
    padding: 0.5rem 0;
  }
  .data-row-wrap.header {
    padding: 0.5rem;
  }

  .expander {
    cursor: pointer;
    user-select: none;
    color: ${vars.plasticsBlue};
  }
`;

export default StyledReviewSurveyRow;
