import { useState, useEffect, useCallback } from "react";
import { getPreSignedUrl } from "src/utils/signedAwsUrl";

function useScorecardDownload(scorecards) {
  const [hasScorecards] = useState(scorecards && scorecards.length > 0);
  const [latestScorecard, setLatestScorecard] = useState(null);
  useEffect(() => {
    if (hasScorecards) {
      setLatestScorecard(scorecards[scorecards.length - 1]);
    }
  }, [setLatestScorecard, hasScorecards, scorecards]);
  const downloadScorecard = useCallback(async () => {
    if (!hasScorecards || !latestScorecard) {
      return;
    }

    const url = await getPreSignedUrl(latestScorecard);
    window.open(url, "_blank", "noreferrer");
  }, [hasScorecards, latestScorecard]);

  return {
    hasScorecards,
    latestScorecard,
    downloadScorecard,
  };
}

export default useScorecardDownload;
