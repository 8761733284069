import React from "react";
import vars from "src/design-system/variables";
import DataRow from "src/design-system/components/data-row/data-row";

const ReviewSubunitHeader = ({ className }) => {
  return (
    <DataRow isHeader columnWidths={vars.reviewSubunitColumnWidths}>
      <div>Subunit Info</div>
      <div>Review</div>
    </DataRow>
  );
};

export default ReviewSubunitHeader;
