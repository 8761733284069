const circulyticsReviewOptions = [
  {
    id: "none",
    display: "Unreviewed",
  },
  {
    id: "false",
    display: "Unapproved",
  },
  {
    id: "true",
    display: "Approved",
  },
];

const associatesReviewOptions = [
  {
    id: "unreviewed",
    display: "Unreviewed",
  },
  {
    id: "pending",
    display: "Pending",
  },
  {
    id: "unapproved",
    display: "Unapproved",
  },
  {
    id: "approved",
    display: "Approved",
  },
];

const associatesSortByOptions = [
  {
    id: "companyName",
    display: "Company Name",
  },
  {
    id: "associateType",
    display: "Type",
  },
  {
    id: "recordedDate",
    display: "Submitted",
  },
  {
    id: "reviewFields.dateOfLastEdit",
    display: "Last edited",
  },
];

const associatesDropdownFilterOptions = [
  {
    id: "all",
    display: "All",
  },
  {
    id: "unreviewed",
    display: "Unreviewed",
  },
  {
    id: "pending",
    display: "Pending",
  },
  {
    id: "unapproved",
    display: "Unapproved",
  },
  {
    id: "approved",
    display: "Approved",
  },
];

const associatesDropdownLanguageOptions = [
  {
    id: "all",
    display: "All",
  },
  {
    id: "EN",
    display: "English",
  },
  {
    id: "ES",
    display: "Spanish",
  },
  {
    id: "PT",
    display: "Portuguese",
  },
];

const associatesDropdownFromTypeOptions = [
  {
    id: "all",
    display: "All",
  },
  {
    id: "business",
    display: "Business",
  },
  {
    id: "city",
    display: "City",
  },
  {
    id: "startup",
    display: "Startup",
  },
];

const associatesDropdownHQRegion = [
  {
    id: "all",
    display: "All",
  },
  {
    id: "Europe",
    display: "Europe",
  },
  {
    id: "North America",
    display: "North America",
  },
  {
    id: "Latin America",
    display: "Latin America",
  },
  {
    id: "Asia",
    display: "Asia",
  },
  {
    id: "Africa",
    display: "Africa",
  },
  {
    id: "Oceania",
    display: "Oceania",
  },
];

export {
  circulyticsReviewOptions,
  associatesReviewOptions,
  associatesSortByOptions,
  associatesDropdownFilterOptions,
  associatesDropdownLanguageOptions,
  associatesDropdownFromTypeOptions,
  associatesDropdownHQRegion,
};
