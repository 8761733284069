import * as React from "react";
import styled from "@emotion/styled/macro";
import vars from "src/design-system/variables";
import Dropdown from "src/design-system/atoms/dropdown";

const ReviewDropdown = ({
  dataTestId,
  className,
  options,
  selected,
  onChange,
  isDisabled,
}) => {
  return (
    <Dropdown
      dataTestId={dataTestId}
      className={`${className} ${selected}`}
      isDisabled={isDisabled || false}
      onChange={onChange}
      options={options}
      selected={selected}
    ></Dropdown>
  );
};

const StyledReviewDropdown = styled(ReviewDropdown)`
  background-color: ${vars.gandalfGrey};
  // TODO: Better way of defining the custom colours?
  &.none {
    color: black;
  }

  &.false {
    color: ${vars.circulyticsRed};
  }

  &.true {
    color: ${vars.learningGreen};
  }

  &.unapproved {
    color: ${vars.circulyticsRed};
  }

  &.approved {
    color: ${vars.learningGreen};
  }

  &.pending {
    color: ${vars.designStrongYellow};
  }
`;

export default StyledReviewDropdown;
