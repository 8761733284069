import React from "react";
import DataRow from "src/design-system/components/data-row/data-row";
import vars from "src/design-system/variables";

const ReviewSurveyHeader = () => {
  return (
    <DataRow isHeader columnWidths={vars.reviewWholeCompanyColumnWidths}>
      <div>Survey Info</div>
      <div>Review</div>
      <div>Commentary</div>
      <div></div>
    </DataRow>
  );
};

export default ReviewSurveyHeader;
