import { API } from "aws-amplify";

const extractParams = (str) => {
  // example
  // dev-circ-398620207634-detailed-results/2022/Nov/5-SU-Full-ColumnJ-FG-2022.09.13-R_3dSMqXAA0N68ela-Detailed-results.xlsx?region=eu-west-2&tab=overview
  // matches[1] = detailed-results
  // matches[2] = 2022/Nov/5-SU-Full-ColumnJ-FG-2022.09.13-R_3dSMqXAA0N68ela-Detailed-results.xlsx
  const patternForObjectBucketAndKey = /^[^/]+?-([a-z-]+?(?=\/))\/(.+.xlsx)/gi;
  const matches = patternForObjectBucketAndKey.exec(str);
  const objectBucket = matches && matches[1];
  const objectKey = matches && matches[2];
  return {
    object_type: objectBucket || str,
    object_name: objectKey || str,
  };
};

const generateSignedUrl = (s3Url) => {
  // s3Url is an URL to the S3 Object to view it using AWS' S3 Web Interface
  // example: https://s3.console.aws.amazon.com/s3/object/dev-circ-398620207634-detailed-results/2022/Nov/5-SU-Full-ColumnJ-FG-2022.09.13-R_3dSMqXAA0N68ela-Detailed-results.xlsx?region=eu-west-2&amp;tab=overview
  const s3ObjectKey = s3Url.replace(
    "https://s3.console.aws.amazon.com/s3/object/",
    ""
  );
  const params = extractParams(s3ObjectKey);
  return API.get(
    "pre-signed-url",
    [
      "?",
      Object.entries(params)
        .map(([k, v]) => `${k}=${v}`)
        .join("&"),
    ].join("")
  );
};

export const getPreSignedUrl = async (s3Url) => {
  const resp = await generateSignedUrl(s3Url);
  return resp.preSignedUrl;
};
