import React from "react";
import styled from "@emotion/styled/macro";
import vars from "src/design-system/variables";

const TooltipText = ({ className, labelText, tooltipText }) => {
  return (
    <div className={className} title={tooltipText}>
      {labelText}
    </div>
  );
};
const StyledTooltipText = styled(TooltipText)`
  :hover {
    border-bottom: 1px dotted ${vars.grimboldGrey};
    cursor: help;
  }
`;
export default StyledTooltipText;
