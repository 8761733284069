import * as React from "react";
import styled from "@emotion/styled/macro";
import vars from "src/design-system/variables";
const Textarea = ({
  props,
  className,
  text,
  maxlength,
  height,
  isDisabled,
  onChange,
  dataTestId,
}) => {
  if (!Number.isFinite(height)) {
    height = "50";
  }
  return (
    <div className={className}>
      {isDisabled ? (
        <p>{text}</p>
      ) : (
        <textarea
          {...props}
          data-testid={dataTestId}
          maxLength={maxlength}
          onChange={onChange}
          name="commentaryText"
          value={text}
          style={{ height: height + "px" }}
        />
      )}
      {maxlength > 0 ? (
        <p className="max-length">
          {text.length} / {maxlength}
        </p>
      ) : (
        ""
      )}
    </div>
  );
};

const StyledTextarea = styled(Textarea)`
  textarea {
    position: relative;
    width: 100%;
    border: 1px solid ${vars.gandalfGrey};
    padding: 0.25rem;
    &:active {
      z-index: 50;
    }
  }
  .max-length {
    font-size: 0.75rem;
    float: right;
    margin: 0.5rem 0;
  }
`;

export default StyledTextarea;
