import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";

import SurveyList from "src/design-system/components/survey-list/survey-list";
import ReviewSurveyHeader from "src/design-system/components/review/review-survey-header/review-survey-header";
import ReviewSurveyRow from "src/design-system/components/review/review-survey-row/review-survey-row";

const ReviewSurveyList = ({ className }) => {
  const [surveys, setSurveys] = useState([]);
  const [pageKey, setPageKey] = useState(null);
  const [hasMoreSurveys, setHasMoreSurveys] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const surveyLimit = 30;

  const getApiData = async () => {
    setIsLoading(true);
    let atLimit = false;
    let tmpSurveys = [...surveys];
    let tmpPageKey = pageKey;
    while (!atLimit) {
      const params = { limit: surveyLimit, unreviewed_only: true };
      if (tmpPageKey) {
        params.last_evaluated_key = JSON.stringify(tmpPageKey);
      }
      await API.get("circulytics-surveys", "/", {
        queryStringParameters: params,
      }).then((res) => {
        console.log("Fetching surveys");
        tmpSurveys = tmpSurveys.concat(res.surveys);
        tmpPageKey = res.last_evaluated_key;
        atLimit = tmpSurveys.length >= surveyLimit || tmpPageKey === null;
        console.log(atLimit);
      });
    }
    setSurveys([...tmpSurveys]);
    setPageKey(tmpPageKey);
    setHasMoreSurveys(tmpPageKey === null);
    setIsLoading(false);
  };
  useEffect(() => {
    getApiData();
  }, []);

  const loadMore = () => {
    if (pageKey) {
      getApiData();
    }
  };

  const updateDataHandle = (updatedRow, rowIndex) => {
    console.log("Updating survey list");
    setSurveys([
      ...surveys.slice(0, rowIndex),
      updatedRow,
      ...surveys.slice(rowIndex + 1),
    ]);
  };
  return (
    <div className={className}>
      <SurveyList
        surveys={surveys}
        isLoading={isLoading}
        hasMoreSurveys={hasMoreSurveys}
        SurveyHeader={ReviewSurveyHeader}
        SurveyItem={ReviewSurveyRow}
        updateDataHandle={updateDataHandle}
        loadMoreHandle={loadMore}
      />
    </div>
  );
};

export default ReviewSurveyList;
