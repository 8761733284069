import React from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import Layout from "src/design-system/layouts/layout";
import ReviewSurveyList from "src/design-system/blocks/review/review-survey-list/review-survey-list";
import PageNav from "src/design-system/components/primary-navigation/circulytics-page-navigation";

const ReviewPage = () => {
  return (
    <Layout>
      <main>
        <title>Review | Circulytics</title>
        <PageNav />
        <h1>Review</h1>
        <p className="intro-text">
          New Circulytics submissions will appear on this page after they have
          been automatically processed by the model. Here you can see each new
          submissions&apos; overall score, their submission details, and their
          draft scorecard.
          <br />
          You can view the submitted data by accessing the{" "}
          <a
            href="https://ellenmacfoundation.eu.qualtrics.com/responses/#/surveys/SV_51FomTALGy5NBFI"
            target="_blank"
            rel="noreferrer"
          >
            &apos;Data & Analysis&apos;
          </a>{" "}
          tab of the Qualtrics survey, using the response ID as the identifier.
          <br />
          Once you have reviewed the data for validity, you must decide whether
          to include the submission in the industry benchmarks, whether to
          include the submission in the company&apos;s historical data chart,
          and whether the submission is permitted for company disclosure.
          <br />
          If applicable, commentaries and additional points can also be added to
          submissions on this page, for later inclusion in the final scorecard
          (generated on the &apos;Scorecards&apos; tab).
        </p>
        <ReviewSurveyList />
      </main>
    </Layout>
  );
};

export default withAuthenticator(ReviewPage);
